<template>
  <div div class="addPerson__wrap" v-show="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="toggle(false)">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.parent }}
          <span> > </span>
          {{ $route.meta.title }}
          <span> > </span>
          添加学校
        </div>
      </div>
    </el-header>

    <div class="form-wrap">
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='110px'
        label-position='top'
        :model='form.data'
        :rules='form.rules'
        size="small"
        hide-required-asterisk
      >
        
        <el-row class="licence_wrap">
          <el-col :span="8" style="width:300px;">
            <el-image
              style="width: 200px; height: 200px;margin:10px 0; border-radius:6px;"
              :src="form.data.certificate"
              fit="cover"
              :preview-src-list="[form.data.certificate]">
                <div slot="error" class="image-slot">
                  <img style="width:200px;height:200px;object-fit: cover;" :src="require('@/assets/img/upload2.png')" />
                </div>
              </el-image>
            <el-upload
              class="upload-demo"
              action=""
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-remove="handleRemove"
              :http-request="httpRequest">
              <div class="imgbtn">办学许可证</div>
            </el-upload>
            
          </el-col>
          <el-col :span="12">
            <el-row :gutter="10">
              <el-col :span="24">
                <el-row :gutter="10">
                  <el-col :span="12">
                    <el-form-item label="" prop="name">
                      <span slot="label" class="label">学校注册名称</span>
                      <el-input
                        v-model="form.data.name"
                        placeholder="请输入学校注册名称">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12" >
                    <el-form-item label="学校简称" prop="simple_name">
                      <el-input
                        v-model="form.data.simple_name"
                        placeholder="请输入机学校简称"
                        clearable>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-form-item label="" prop="legal">
                  <span slot="label" class="label">校长姓名</span>
                  <el-input
                    v-model="form.data.legal"
                    placeholder="请输入校长姓名">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="" prop="organ_code">
                  <span slot="label" class="label">代码</span>
                  <el-input
                    v-model="form.data.organ_code"
                    placeholder="请输入学校代码">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="" prop="area_arr">
                  <span slot="label" class="label">注册地址</span>
                  <el-row :gutter="10">
                    <el-col :span="12">
                      <el-cascader
                        style="width:100%;"
                        :popper-append-to-body="false"
                        popper-class="areaCascaderWrap"
                        v-model="form.data.area_arr"
                        :options="areaArr"
                        :props="{ checkStrictly: false }"
                        >
                      </el-cascader>
                    </el-col>
                    <el-col :span="12">
                      <el-input
                        v-model="form.data.address"
                        placeholder="请输入详细地址"
                        type="textarea"
                        :autosize="{ minRows: 1, maxRows: 2}">
                      </el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="学校属性" prop="nature">
              <el-select v-model="form.data.nature" placeholder="学校属性">
                <el-option label="公办" :value="1"></el-option>
                <el-option label="民办" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="学校类型" prop="type">
              <el-select v-model="form.data.type" placeholder="学校类型">
                <el-option v-for="item in schoolType" :key="item.id" :label="item.value" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="联系人" prop="contact_person">
              <el-input
                v-model="form.data.contact_person"
                placeholder="请输入联系人"
                clearable>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="" prop="contact_phone">
              <template slot="label">联系电话</template>
              <span class="label-hint">（可用于登录学校管理端，初始密码123456）</span>
              <el-input
                v-model="form.data.contact_phone"
                maxlength="11"
                placeholder="请输入联系人手机号码"
                clearable>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="学校简介" prop="introduce">
              <el-input
                v-model="form.data.introduce"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 8}"
                placeholder="请输入学校简介"
                clearable
                maxlength="500"
                show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="学校照片" prop="images" class="imageWrap">
              <template slot="label">学校照片</template>
              <span class="label-hint">（至少上传1张，限9张）</span>
              <el-upload
                action=""
                :limit="9"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :file-list="fileList"
                :http-request="httpRequestBack"
                accept="image/jpeg, image/png, image/gif">
                <img style="104px;height:104px;object-fit: cover;" :src="require('@/assets/img/upload.png')" />
              </el-upload>
              <el-dialog :visible.sync="dialogVisible" append-to-body>
                <template #title>
                  <div class="el-dialog-title">查看图片</div>
                </template>
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 创建 -->
    <div class="licence_foot">
      <el-button  @click="toggle(false)">取消</el-button>
      <el-button type="primary" @click="submitForm">提交</el-button>
    </div>
   
  </div>
</template>


<script>
import { REG_EXP } from '@/util'
export default {
  name: 'SchoolEdit',
  props:['schoolType'],
  data() {
    return {
      loading: false,
      visible: false,
      isChange: false,
      form: {
        data: {
          name: "", //名称
          legal: "", //校长
          certificate: "", //办学许可证
          organ_code: "", //组织代码
          simple_name: "", //学校简称
          area_arr: [],
          province: 0, //省
          city: 0, //市
          area: 0, //区
          address: "", //详细地址
          nature: '', //属性 1:公办 2:民办
          type: '', //学校类型
          contact_person: "", //联系人
          contact_phone: "", //联系人电话
          introduce: "", //学校简介
          images: "" //详情图片 多张逗号隔开
        },
        rules: {
          name: [{ required: true, message:'必填项', trigger: 'change' }],
          legal: [{ required: true, message:'必填项', trigger: 'change' }],
          certificate: [{ required: true, message:'必填项', trigger: 'change' }],
          organ_code: [{ required: true, message:'必填项', trigger: 'change' }],
          simple_name: [{ required: true, message:'必填项', trigger: 'change' }],
          address: [{ required: true, message:'必填项', trigger: 'change' }],
          nature: [{ required: true, message:'必填项', trigger: 'change' }],
          type: [{ required: true, message:'必填项', trigger: 'change' }],
          contact_person: [{ required: true, message:'必填项', trigger: 'change' }],
          contact_phone: [
            { required: true, message:'必填项', trigger: 'change' },
            { pattern: REG_EXP.mobilePhone, message: "手机号不正确", trigger: 'blur' }
          ],
          area_arr: [{ required: true, message:'必填项', trigger: 'change' }],
        },
      },
      fileList: [],
      dialogVisible: false,
      dialogImageUrl: [],
      areaArr: [],
    }
  },
  created() {
    this.getArea();
  },
  methods: {
    // 详情
    getDetail(row) {
      if(!!row.id) {
        this.isChange = true;
        this.$http.get('/admin/school/info',{ params:{ id:row.id } }).then(res => {
          if(res.code == 1) {
            let info = res.data;
            Object.keys(info).forEach(item => {
              this.form.data[item] = info[item]
            })
            this.form.data.area_arr = [res.data.province,res.data.city,res.data.area];
            var _space_url = this.form.data.images
            if(_space_url.length > 0) {
              this.fileList = []
              _space_url.forEach(v => {
                let str = {
                  name: '',
                  url: v
                }
                this.fileList.push(str)
              })
            }
          }
        })
      }
    },
    reset(done) {
      this.isChange = false
      this.$refs.elFormDom.resetFields()
      this.fileList = [];
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
    // 获取省市区
    getArea() {
      this.$http.post('/common/district/all',{}).then(res => {
        if(res.code == 1) {
          this.areaArr = res.data.list;
        }
      })
    },
    httpRequest(file) {
      let formData = new FormData();
      formData.append('file', file.file);
      this.loading = true;
      this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
        this.form.data.certificate = res.data.all_path_url;
        this.$refs.elFormDom.clearValidate();
      }).finally(() => {
        this.loading = false;
      })
    },
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error('上传文件大小不能超过 5MB!');
      }
      return isLt5M;
    },
    
    // 详情图片上传
    httpRequestBack(file) {
      let formData = new FormData();
      formData.append('file', file.file);
      this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
        if(res.code === 1) {
          let str = {
            url: res.data.all_path_url,
            name: file.file.name,
            uid: file.file.uid
          }
          this.fileList.push(str);
          this.form.data.images = res.data.all_path_url;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file) {
      this.fileList.forEach((v,i) => {
        if(file.uid == v.uid) {
          this.fileList.splice(i, 1)
        }
      })
    },
    submitForm() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          let _params = {...this.form.data}
          if(this.fileList.length > 0){
            let _image = []
            this.fileList.forEach(v => {
              _image.push(v.url)
            })
            _params.images = _image.join(",")
          } else {
            _params.images = '';
          }
          _params.province = this.form.data.area_arr[0];
          _params.city = this.form.data.area_arr[1];
          _params.area = this.form.data.area_arr[2];
          delete _params.area_arr;
          let apiUrl = this.isChange ? '/admin/school/edit' : '/admin/school/add';
          this.$http.post(apiUrl, _params).then(res => {
            if(res.code == 1) {
              this.$message.success('提交成功！')
              this.$emit('refresh')
              this.toggle(false)
            }
          })
        }
      })
    },
    
  }
}
</script>
<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #F1F4FC;
  z-index: 5 !important;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}
.page-header__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  color: #595959;

  .title {
    height: 58px;
    line-height: 58px;
  }

  .site {
    color: #898989;
  }
  
  .backBtn {
    height: 30px;
    border: solid 1px #007DF2;
    line-height: 30px;
    padding: 0 9px;
    border-radius: 3px;
    background: #E5F2FE;
    color: #007DF2;
    cursor: pointer;
    margin: 15px 20px 15px 0;
  }
  .back-img {
    width: 18px;
    height: 16px;
    position: relative;
    top: 3px;
    margin-right: 10px;
  }
}
.saft_form {
  padding: 20px;
}
.form-wrap {
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  margin: 20px;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}
.itemTime {
  display: block;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #409EFF;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}



</style>
<style scoped>
.imageWrap ::v-deep .el-upload--picture-card {
  border: none;
  width: 104px;
  height: 104px;
}
.imageWrap ::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 104px;
  height: 104px;
}
.imageWrap ::v-deep .el-range-editor--small .el-range-separator {
  width: 20px;
}
.licence_foot {
  background: #fff;
  padding: 5px 20px;
  margin-top: auto;
  text-align: right;
}
.licence_wrap {
  background: #FCFCFC;
  border: solid 1px #E5E5E5;
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 10px;
}
.licence_foot {
  background: #fff;
  padding: 10px 20px;
  margin-top: auto;
  text-align: right;
}
.space-between {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
}
.justify-between {
  justify-content: space-between;
}
.imghint {
  width: 160px;
  font-size: 12px;
  color: #8C8C8C;
  text-align: center;
  line-height: 20px;
}
.imgtip {
  font-size: 12px;
  color: rgb(243, 81, 73);
  bottom: 26px;
  position: absolute;
  left: 10px;
  width: 130px;
}
.imgbtn {
  width: 200px;
  border: solid 1px #007DF2;
  color: #007DF2;
  border-radius: 2px;
  height: 36px;
  line-height: 36px;
}
</style>